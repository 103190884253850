.equationEditorWrap {
    display: flex;
    flex: 1;
}

.equationEditorWrap .qstTreeWrap {
    flex: 0 0 40%;
    width: 40%;
    margin-right: 16px;
}

.equationEditorWrap .tabContent {
    flex: none;
    height: calc(100vh - 240px);
    overflow-y: auto;
}

.tabContent h3 {
    font-size: 1.25rem;
    margin-bottom: 12px;
}

.rankingTextarea {
    height: 140px;
    font-size: 0.875rem;
    border-color: #969696;
}

.tabContent .rta {
    height: auto;
    margin-bottom: 16px;
}

.ant-btn.linkBtn2 {
    margin: 0 auto 0 0;
}

.ant-btn.linkBtn2,
.linkBtn2.ant-btn-default:not(:disabled):hover {
    border: none;
    background: transparent;
    color: var(--mnq-primary);
    padding: 0;
    text-decoration: underline;
}

.linkBtn2.ant-btn-default:not(:disabled):hover {
    color: var(--mnq-dark-01);
    text-decoration: none;
}

.equationEditorWrap .ant-tag {
    background: #617E8C;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 400;
    border: none;
}

.equationEditorWrap .qstTreeWrap {
    max-height: calc(100vh - 200px);
}

.qstTreeWrap .samplingList .qstIconNumber {
    min-height: 22px;
}

.qstTreeWrap .qstRowBlk .titleTxt {
    line-height: 1.5rem;
}

.qstTreeWrap li > ul {
    margin: 6px 0 16px 20px;
    list-style-type: disc;
}

.qstTreeWrap li > ul li {
    margin: 0 0 6px 20px;
}

.rta__autocomplete {
    max-width: 500px;
    z-index: 9;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
}

.rta__list {
    font-size: 0.75rem;
}

.entryName {
    font-weight: 600;
}

.rta__entity {
    padding: 4px;
}

.rta__entity--selected {
    background-color: var(--mnq-primary);
}