/* empty */

.strikethrough-text {
    text-decoration: line-through;
    color: inherit;
}

.tblTitle.strikethrough-text a {
    text-decoration: line-through underline;
    color: inherit;
}

.justify-align {
    width: 16px;
    height: 16px;
}

.heading {
    font-weight: 400;
}

.font-select {
    width: 150px;
    min-width: 150px;
    max-width: 200px;
}

.disabled-editor {
    background-color: #efefef;
}

.ant-upload-picture-card-wrapper:focus-within .ant-upload.ant-upload-select {
    outline: 2px solid #c9858a;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
    border-radius: 3px;
    z-index: 1;
}

.explore-question-card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.explore-question-card-active {
    background-color: #c8e6c9a3
}

.preview-criteria-card {
    margin: 8px;
    margin-right: 14px;
    border: 1 px solid black;
}

.filter-question-option {
    display: flex;
    align-items: center;
}

.filter-question-options-block {
    display: flex;
    margin: 5px;
    font-size: small;
    background-color: #f0f0f0;
    align-items: center;
    min-width: fit-content;
}

.filter-option-text {
    padding-left: 5px;
}

.filter-question-options-block button {
    border: none;
    background-color: inherit;
    border-radius: 2px;
}

.profileViewFilterBlk {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profileViewFilterBlk .profileViewSortActions {
    display: flex;
}

.profileViewFilterBlk .profileViewSortActions button.inactive {
    color: var(--mnq-bdr-color);
}

.profileViewFilterBlk .ant-btn>.anticon+span {
    margin-inline-start: 2px;
}

.previewLegendsWrapper {
    margin-top: 10px;
}

.previewLegendsWrapper li {
    display: flex;
    align-items: center;
}

.previewLegendsWrapper li span {
    margin: 2px;
}

.previewLegendsWrapper .legendColor {
    height: 15px;
    width: 15px;
    display: block;
}

.previewCardFlipContainer {
    perspective: 1000px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.previewCardFlipContainer.flipped {
    transform: rotateY(180deg);
}

.siteInfoBlkFooter {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0 0;
}

.siteInfoBlkFooter button:not(:last-child) {
    margin-right: 8px;
}

/** Caution Note TextArea Custom Styles */
.textAreaWrapper textarea {
    resize: vertical !important;
}

.blinkSiteRow,
.blinkSiteRow .ant-table-column-sort {
    animation: blinkRow 7s ease-in-out !important;
}

.blinkInvalidRow,
.blinkInvalidRow .ant-table-column-sort {
    background-color: var(--mnq-primary-bg);
}

@keyframes blinkRow {
    0% {
        background-color: var(--mnq-primary-bg);
    }

    100% {
        background-color: inherit;
    }
}

.disabled-date-overlay {
    position: relative;
    display: inline-block;
}

.disabled-date-overlay::before {
    content: '';
    position: absolute;
    top: 15%;
    left: 30%;
    width: 100%;
    height: 2px;
    background-color: var(--mnq-error-border);
    transform-origin: top right;
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: 1;
}

.promtHide {
    position: absolute; border: 0px; width: 1px; height: 1px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; overflow-wrap: normal;
}

.getIframeButton:disabled,
.getIframeButton[disabled]{
    opacity: 0.6;
}

.importerStatusSteps {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    padding: 0 14%;
}

.importerInputs {
    width: 100%;
    border: none;
    box-shadow: none;
    outline: none;
}

.importerInputs .ant-input {
    color: var(--mnq-dark-01);
    background-color: rgba(255, 255, 255, 0);
}

.importerHeaderTitle {
    white-space: nowrap;
}

.textEllipsis {
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis */
    display: -webkit-box; /* For webkit browsers */
    -webkit-box-orient: vertical;
    overflow-y: hidden; /* Hide vertical overflow */
    -webkit-line-clamp: 2; /* Limit to 7 lines */
}

.importerToolSaveData {
    background-color: var(--mnq-dark-01);
    border-color: var(--mnq-dark-01);
    color: var(--mnq-light-05);
}

.importerToolSaveData:hover {
    color: var(--mnq-light-05) !important;
    background-color: var(--mnq-primary) !important;
    border-color: var(--mnq-primary) !important;
}

.drawerContent,
.suggestiveContent{
    justify-content: flex-end;
}
